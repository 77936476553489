<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>Hr系统</el-breadcrumb-item>
        <el-breadcrumb-item>薪资管理</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form label-width="70px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="query.keyword" prefix-icon="Search" @keyup.enter="fetchs()"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="月份" prop="salaryDate">
          <el-col>
            <el-date-picker v-model="salaryDate" type="month" placeholder="请选择查询的月份" @change="fetchs()" style="width: 160px;" />
          </el-col>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()">
            搜索
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <template v-if="!pwdVisible">
      <div class="toolbar">
        <el-button type="danger" icon="delete" @click="deleter()">删除</el-button>

        <el-dropdown style="margin-left:20px;">
          <el-button plain type="primary">
            <el-icon style="margin-right:5px;">
              <Upload />
            </el-icon>
            数据操作
          </el-button>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="download()">导出信息</el-dropdown-item>
              <el-dropdown-item @click="visible = true">批量导入</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>

      <basic-table ref="table" class="table" :query="query" @handler="fetchs"></basic-table>

      <component ref="dialog" :visible="visible" @change="changeHandler" @submit="submitHandler"
        :columns="query.columns" is="importer" />
    </template>

    <!--管理员密码弹框-->
    <el-dialog v-model="pwdVisible" title="请输入管理员密码通过二次验证后进行查阅" :close-on-click-modal="false"
      :close-on-press-escape="false" :show-close="false">
      <el-input v-model="pwdValue" placeholder="请输入密码后回车"
        @keyup.enter="if (pwdValue == 'super') { pwdVisible = false; fetchs(); }" style="max-width: 200px;"></el-input>
    </el-dialog>
  </el-card>
</template>

<script>
//import kaung from '@/utils/kaung.js'  //kuang.fetch

import StaticDialog from "@/components/static-dialog.vue";
import BasicTable from "@/components/basic-table.vue";
export default {
  name: "HrSalary",
  components: { StaticDialog, BasicTable },
  data() {
    return {
      query: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },

          { prop: "salaryYear", label: "年份", width: "50", align: 'center' },
          { prop: "salaryMonth", label: "月份", width: "45", align: 'center' },

          { prop: "companyName", label: "公司名", width: "140", align: 'center', sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "departmentName", label: "部门名", width: "80", align: 'center', sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "employeeName", label: "员工", width: "75", align: 'center', sortable: true, searchable: true, showOverflowTooltip: true },

          { prop: "basicSalaryFee", label: "基本工资", width: "65", align: 'right', showOverflowTooltip: true },
          { prop: "basicConfidentialFee", label: "保密费", width: "55", align: 'right', showOverflowTooltip: true },
          { prop: "basicPositionFee", label: "岗位工资", width: "65", align: 'right', showOverflowTooltip: true },
          { prop: "basicPerformanceFee", label: "绩效工资", width: "65", align: 'right', showOverflowTooltip: true },
          { prop: "basicAllowanceFee", label: "津补贴", width: "55", align: 'right', showOverflowTooltip: true },
          { prop: "basicTotalFee", label: "基础工资", width: "65", align: 'right', showOverflowTooltip: true },

          { prop: "otherBonusFee", label: "绩效奖金", width: "65", align: 'right', showOverflowTooltip: true },
          { prop: "otherIncomeFee", label: "其他收入", width: "65", align: 'right', showOverflowTooltip: true },
          { prop: "overtimeFee", label: "加班费", width: "65", align: 'right', showOverflowTooltip: true },
          { prop: "commissionFee", label: "实发提成", width: "65", align: 'right', showOverflowTooltip: true },

          { prop: "absenceHours", label: "缺勤小时数", width: "80", align: 'center', showOverflowTooltip: true },
          { prop: "attendanceDeductionFee", label: "考勤扣款", width: "65", align: 'right', showOverflowTooltip: true },

          { prop: "otherAllowanceFee", label: "其他补贴", width: "65", align: 'right', showOverflowTooltip: true },

          { prop: "otherDeductionFee", label: "其他扣款", width: "65", align: 'right', showOverflowTooltip: true },
          { prop: "withholdingFee", label: "其他代扣款", width: "80", align: 'right', showOverflowTooltip: true },

          { prop: "grossSalaryFee", label: "应发工资", width: "65", align: 'right', showOverflowTooltip: true },

          { prop: "personalSocialSecurityFee", label: "个人社保", width: "65", align: 'right', showOverflowTooltip: true },
          { prop: "personalHousingFundFee", label: "个人公积金", width: "80", align: 'right', showOverflowTooltip: true },
          { prop: "taxableIncomeFee", label: "纳税所得额", width: "80", align: 'right', showOverflowTooltip: true },

          { prop: "specialDeductionFee", label: "专项扣除", width: "65", align: 'right', showOverflowTooltip: true },
          { prop: "incomeTaxFee", label: "个人所得税", width: "80", align: 'right', showOverflowTooltip: true },
          { prop: "netSalaryFee", label: "银行实发工资", width: "90", align: 'right', showOverflowTooltip: true },

          { prop: "reimbursementFee", label: "对公报销发", width: "80", align: 'right', showOverflowTooltip: true },
          { prop: "privatePaymentFee", label: "对私实发", width: "65", align: 'right', showOverflowTooltip: true },

          { prop: "actualPaymentFee", label: "实发工资合计", width: "90", align: 'right', showOverflowTooltip: true },

          { prop: "companySocialSecurityFee", label: "公司承担社保", width: "90", align: 'right', showOverflowTooltip: true },
          { prop: "companyHousingFundFee", label: "公司承担公积金", width: "105", align: 'right', showOverflowTooltip: true },
          { prop: "totalLaborCostFee", label: "单位合计成本", width: "120", align: 'right', sortable: true, showOverflowTooltip: true },

          { prop: "remark", label: "备注", searchable: true, width: "130", showOverflowTooltip: true },

          // { prop: "createUserName", label: "创建人", searchable: true, width: "90", showOverflowTooltip: true },
          // { prop: "createDate", label: "创建时间", sortable: true, width: "120", showOverflowTooltip: true },
          // { prop: "updateUserName", label: "更新人", searchable: true, width: "90", showOverflowTooltip: true },
          // { prop: "updateDate", label: "更新时间", sortable: true, width: "120", showOverflowTooltip: true },
        ],
        filters: [],
        sidx: "id",
        sord: "desc"
      },
      salaryDate: null,
      visible: false,
      pwdVisible: true,
      pwdValue: '',
    };
  },
  mounted() {
    //kaung插件初始化
    this.$kaung.init(this.$options.name);
    this.fetchs();
  },
  methods: {
    fetchs() {
      this.query.filters = [];
      if (this.salaryDate) {
        let salaryYear = this.$util.fmtDate(this.salaryDate, "yyyy");
        let salaryMonth = this.$util.fmtDate(this.salaryDate, "MM");
        this.query.filters.push({ name: "salaryYear", value: salaryYear, operate: 0 });
        this.query.filters.push({ name: "salaryMonth", value: salaryMonth, operate: 0 });
      }
      let { data, records, total, ...params } = { ...this.query };
      this.$kaung.search(params).then((res) => {
        this.query = { ...res.data };
      });
    },
    download() {
      let { data, records, total, ...params } = { ...this.query };
      this.$kaung.export(params).then((res) => {
        this.$notify({
          title: '表格数据导出',
          message: res.content,
          type: res.type,
          duration: 10000
        });
        window.location.href = process.env.VUE_APP_BASEURL + res.data;
      });
    },

    deleter() {
      if (!this.$refs.table.chooseRow) {
        this.$message({ message: '请点击选择要删除的数据行', type: 'warning' });
        return;
      }

      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", })
        .then(() => {
          this.$kaung.delete(that.$refs.table.chooseRow.id).then((res) => {
            if (res.code === 200) {
              that.$message({ message: res.content, type: res.type });
              that.fetchs();
            }
          });
        });
    },

    changeHandler(value) {
      this.visible = value;
    },
    submitHandler(lists) {
      this.$kaung.fetch({ url: this.$options.name + '/Importer', data: lists }).then((res) => {
        if (res.code === 200) {
          this.$message({ message: res.content, type: res.type });
          this.fetchs();
        }
      });
    }
  },
};
</script>